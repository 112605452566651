<template>
  <section class="page">
    <div class="loader is-visible"></div>
  </section>
</template>
<script>
export default {
  async mounted() {
    let returnUrl = this.$route.query.returnUrl;
    let companyGuid = this.$route.query.companyGuid;

    if (this.$store.user.loggedIn) {
      if (companyGuid) await this.$store.user.connectToCompany(companyGuid);

      this.$router.replace(returnUrl);
    } else {
      let { status, message } = await this.$store.user.externalLogin(companyGuid);

      this.$notify({
        type: status,
        title: message,
      });

      this.$router.replace(returnUrl);
    }
  },
};
</script>
